<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>

        </v-app-bar>
        <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'mx-0' : 'row content mx-0'">
        
        <v-row align-content="center" justify="center">
            <v-col cols="10" md="8" lg="6">
                <div class="headline pl-0">Event</div>
                <v-card-subtitle class="pt-0 pl-0">Please enter the event id to get the event information</v-card-subtitle>

                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="eventNum" outlined placeholder="Event Number" v-mask="'#######'" @keypress.enter="loadEvent"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="phoneNum" outlined placeholder="Coordinator Phone Number" v-mask="'(###) ###-####'" @keypress.enter="loadEvent"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-btn block depressed :x-large="!isMobile" color="grey darken-4" class="white--text" :loading="loading" @click="loadEvent">Find</v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="event != null">
                    <v-col cols="12">
                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <td>Event num</td>
                                    <td>{{ event.foreignKey }}</td>
                                </tr>
                                <tr>
                                    <td>Event type</td>
                                    <td>{{ event.eventType }}</td>
                                </tr>
                                <tr>
                                    <td>Event date</td>
                                    <td>{{ event.eventDate }}</td>
                                </tr>
                                <tr>
                                    <td>Location</td>
                                    <td>{{ event.location }}</td>
                                </tr>
                                <tr>
                                    <td>Coordinators</td>
                                    <td>{{ coordinatorNames }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>

        <v-btn absolute bottom right depressed x-large color="primary" @click="nextStep">
            <v-icon left>mdi-arrow-right</v-icon> Next
        </v-btn>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { API } from '@/inc/api';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        event       : null,
        eventNum    : '',
        phoneNum    : '',
        loading     : false,
        isMobile    : false,
    }),
    computed: {
        ...mapGetters({

        }),
        coordinatorNames() {
            if( this.event.coordinator1Id != 0 && this.event.coordinator2Id != 0 )
                return `${this.event.coordinator1.fullName} & ${this.event.corrdinator2.fullName}`;

            if( this.event.coordinator1Id != 0 )
                return this.event.coordinator1.fullName;

            return this.event.corrdinator2.fullName;
        }
    },
    mounted() {
        this.onResize();
    },
    methods: {
        ...mapActions({
            setEvent    : 'setEvent',
            setUseDate  : 'setUseDate'
        }),
        async loadEvent() {
            if( this.eventNum.length == 0 || this.phoneNum.length == 0 )
                return;

            try {
                let api = new API();

                this.loading = true;
                let res = await api.post('/event', {
                    eventNum: this.eventNum,
                    phoneNum: this.phoneNum
                });
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error ? 'Error' : 'Warning', res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.event = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage('Error', error, 'error');
            }
        },
        nextStep() {
            if( this.event == null )
                return;

            this.setEvent({
                id                      : this.event.id,
                foreignKey              : this.event.foreignKey,
                occasion                : this.event.occasion,
                location                : this.event.location,
                eventType               : this.event.eventType,
                eventDate               : this.event.eventDate,
                coordinator1FullName    : this.event.coordinator1Id == 0 ? '' : this.event.coordinator1.fullName,
                coordinator2FullName    : this.event.coordinator2Id == 0 ? '' : this.event.coordinator2.fullName,
                coordinatorPhone        : this.phoneNum
            });
            this.setUseDate( this.event.eventDate );
            this.$router.push('customer-info')
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
    },
    directives: { mask }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}
</style>